import React from 'react';

import Layout from '../components/layout';

export default (props) => {
    return (
        <Layout location={props.location}>
        <h1 className="major">
            Page inexistante
        </h1>
        <p>Désolé, cette page n'existe pas.</p>
        </Layout>
    );
}